import { MsalAuthenticationTemplate, useMsal, MsalAuthenticationResult } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import GuestPortalApiService from "../Services/GuestPortalApiService";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import { getError } from "../appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import VisitReasonModel from "../Models/VisitReasonModel";
import { useForm } from "react-hook-form";
import { useMemo } from "react";

function VisitReasonContent() {
    const appInsights = useAppInsightsContext();
    const { instance } = useMsal();
    const { register, formState: { errors }, handleSubmit } = useForm<VisitReasonModel>({ mode: "onChange" });
    const service = useMemo(() => new GuestPortalApiService(instance), [instance]);
    const history = useHistory();

    const onSubmit = handleSubmit(async data => {
        async function save(data: VisitReasonModel) {
            try {
                await service.addVisitReason(data);

                toast.success("Bezoekreden toevoegen gelukt");
            }
            catch (error) {
                toast.error("Fout bij toevoegen bezoekreden toevoegen.");
                appInsights.trackException({ exception: getError(error), severityLevel: SeverityLevel.Error });
            }
        }

        save(data).then(() => history.push('/visitreasons'));
    });

    return (
        <form onSubmit={onSubmit} className="mx-3" autoComplete="off">
            <div className="mb-2">
                <label className="form-label">Reden van bedrijfsbezoek</label>
                <input {...register("reason", { required: true })} className={"form-control " + (errors.reason && "is-invalid")} placeholder="Reden van bedrijfsbezoek" />
                {errors.reason && <div className="form-text text-danger">Reden is verplicht</div>}
            </div>
            <div className="row">
                <div>
                    <button type="submit" className="btn btn-primary text-light" >Toevoegen</button>
                </div>
            </div>
        </form>
    );
};

function ErrorComponent(authRes: MsalAuthenticationResult) {
    return <p>An Error Occurred: {authRes.error?.message}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

export default function Guest() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            <VisitReasonContent />
        </MsalAuthenticationTemplate>
    )
};