import { useEffect, useState, useMemo, useCallback } from "react";
import { MsalAuthenticationTemplate, useMsal, MsalAuthenticationResult } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import GuestPortalApiService from "../Services/GuestPortalApiService";
import { toast } from 'react-toastify';
import VisitReasonModel from "../Models/VisitReasonModel";
import { Link } from "react-router-dom";

function VisitReasonsContent() {
    const { instance } = useMsal();
    const [visitReasons, setVisitReasons] = useState<VisitReasonModel[]>();
    const service = useMemo(() => new GuestPortalApiService(instance), [instance]);

    const init = useCallback(async () => {
        try {
            let visitReasons = await service.getVisitReasons();
            setVisitReasons(visitReasons);
        }
        catch (error) {
            toast.error("Fout bij ophalen.");
        }
    }, [service]);

    useEffect(() => {
        init();
    }, [init]);

    async function deleteVisitReason(id: number) {
        try {
            await service.deleteVisitReason(id);
            init();
        }
        catch (error) {
            toast.error("Fout bij ophalen.");
        }
    }

    return (
        <>
            <div className="row">
                <div className="col table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Reden Bezoek</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {visitReasons?.map(reason => (
                                <tr key={reason.id}>
                                    <td>{reason.reason}</td>
                                    <td>
                                        <button className="btn btn-primary text-light btn-sm float-end" onClick={() => deleteVisitReason(reason.id)}>Verwijderen</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="row">
                <div>
                    <Link to="/visitreason" className="btn btn-primary text-light">Nieuwe toevoegen</Link>
                </div>
            </div>
        </>
    );
};

function ErrorComponent(authRes: MsalAuthenticationResult) {
    return <p>An Error Occurred: {authRes.error?.message}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

export default function ConfigurationPage() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            <VisitReasonsContent />
        </MsalAuthenticationTemplate>
    )
};