import { useLocation } from "react-router-dom";

function Footer(props: any) {
    const languages = ["nl", "de", "en", "fr", "pl", "pt", "ro", "tr"]
    const location = useLocation();


    const FooterContent = () => {
        return (
            <footer className="footer mt-auto py-3">
                <div className="container border-top pt-2">
                    <p className="col-md-12 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">&copy; {(new Date().getFullYear())} Behind Your Business</p>
                </div>
            </footer>
        );
    };


    function isGuestLocation() {
        if (location.pathname === "/choice")
            return true;
        else if (location.pathname === "/guest")
            return true;
        else if (location.pathname === "/logoutGuest")
            return true;
        else
            return false;
    }

    return (
        <>
            {isGuestLocation() ?
                <footer className="footer mt-auto py-3">
                    <div className="container border-top pt-2 d-flex justify-content-between align-items-baseline">
                        <div>
                            <select onChange={props.handleChange} className='form-control custom-form-control '>
                                {languages.map((option) => (
                                    <option key={option} value={option}>{option.toUpperCase()}</option>
                                ))}
                            </select>
                        </div>
                        <p className="mb-3 mb-md-0 link-dark text-decoration-none">&copy; {(new Date().getFullYear())} Behind Your Business</p>
                        <p></p>
                    </div>
                </footer>
                : <FooterContent />}
        </>

    );
}

export default Footer;