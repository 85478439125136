import { forwardRef } from 'react';
import TextField from '@mui/material/TextField';

const MuiPhoneInput = (props: any, ref: any) => {

    return (

        <TextField
            {...props}
            inputRef={ref}
            fullWidth
            label='GSM'
            variant='outlined'
            margin="dense"
            name='phone'
        />
    )
}
export default forwardRef(MuiPhoneInput)