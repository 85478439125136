import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from "./Pages/Home";
import Choice from "./Pages/Choice";
import LogoutGuest from "./Pages/LogoutGuest";
import Guest from "./Pages/Guest";
import GuestsPage from "./Pages/Guests"
import VisitReasonPage from "./Pages/VisitReason";
import VisitReasonsPage from "./Pages/VisitReasons";
import ConfigurationPage from "./Pages/Configuration";
import { ToastContainer } from 'react-toastify';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from "./authConfig";
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { MsalAuthenticationResult, MsalAuthenticationTemplate } from '@azure/msal-react';
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./appInsights";
import './i18n/config';
import { useTranslation } from 'react-i18next';

function ErrorComponent(authRes: MsalAuthenticationResult) {
    return <p>An Error Occurred: {authRes.error?.message}</p>;
}

function App() {
    const { i18n } = useTranslation();

    const changeLanguage = (e: any) => {
        i18n.changeLanguage(e.target.value);
    };

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={loginRequest}
                errorComponent={ErrorComponent}
            >
                <Router>
                    <Header />
                    <div className="container">
                        <Switch>
                            <Route path="/choice">
                                <Choice />
                            </Route>
                            <Route path="/logoutGuest">
                                <LogoutGuest />
                            </Route>
                            <Route path="/guest">
                                <Guest />
                            </Route>
                            <Route path="/config">
                                <ConfigurationPage />
                            </Route>
                            <Route path="/guests">
                                <GuestsPage />
                            </Route>
                            <Route path="/visitreason">
                                <VisitReasonPage />
                            </Route>
                            <Route path="/visitreasons">
                                <VisitReasonsPage />
                            </Route>
                            <Route path="/">
                                <Home />
                            </Route>
                        </Switch>
                        <ToastContainer />
                    </div>
                    <Footer
                        handleChange={changeLanguage}
                    />
                </Router>
            </MsalAuthenticationTemplate>
        </AppInsightsContext.Provider>
    );
}

export default App;
