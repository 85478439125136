import { useEffect, useState, useMemo, useCallback } from "react";
import { MsalAuthenticationTemplate, useMsal, MsalAuthenticationResult } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import GuestPortalApiService from "../Services/GuestPortalApiService";
import GuestModel from "../Models/GuestModel";
import { toast } from 'react-toastify';
import DatePicker, { registerLocale } from "react-datepicker";
import nlBE from 'date-fns/locale/nl-BE';
import 'react-dropdown/style.css';
import { format } from "date-fns";
import ConfigurationModel from "../Models/ConfigurationModel";

registerLocale('nlBE', nlBE);

function GuestsContent() {
    const { instance } = useMsal();
    const [guests, setGuests] = useState<GuestModel[]>();
    const [configuration, setConfiguration] = useState<ConfigurationModel>();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const service = useMemo(() => new GuestPortalApiService(instance), [instance]);

    const init = useCallback(async () => {
        try {
            let guests = await service.getGuestsFromPeriod(startDate, endDate);
            setGuests(guests);
        }
        catch (error) {
            toast.error("Fout bij ophalen.");
        }
    }, [service, startDate, endDate]);

    useEffect(() => {
        init();
    }, [startDate, endDate, init]);

    useEffect(() => {
        async function config() {
            try {
                const config = await service.getConfiguration();
                setConfiguration(config);
            }
            catch (error) {
                toast.error("Fout bij ophalen.");
            }
        };

        config();
    }, [service]);

    async function updateGuest(id: number) {
        try {
            await service.updateGuest(id);
            init();
        }
        catch (error) {
            toast.error("Fout bij ophalen.");
        }
    }

    function isSignOutDate(guest: any) {
        if (guest?.signOutDate === undefined)
            return "/";
        else
            return new Date(guest.signOutDate).toLocaleString("nl-BE");
    }

    const exportRegistraties = async () => {
        try {
            if (guests) {
                const fileDataArray = guests.map((g) => {
                    const separator = ",";
                    let isPresent = 'Afwezig'
                    let signOutDate: Date | string = g.signOutDate;
                    if (signOutDate === undefined)
                        signOutDate = '';

                    if (g.isPresent) {
                        isPresent = 'Aanwezig'
                    }

                    return `"${g.firstName}"` + separator +
                        `"${g.lastName}"` + separator +
                        `"${g.department}"` + separator +
                        `"${g.city ?? ""}"` + separator +
                        `"${g.companyName ?? ""}"` + separator +
                        `"${g.phone ?? ""}"` + separator +
                        `"${g.personVisited ?? ""}"` + separator +
                        `"${isPresent}"` + separator +
                        `"${g.date}"` + separator +
                        `"${signOutDate}"`
                });

                let fileData = fileDataArray.filter(line => line !== null).join("\r\n");
                fileData = `Voornaam,Achternaam,Afdeling,Woonplaats,Bedrijf,GSM,Reden van bezoek,Aanwezigheid,Aangemeld op,Afgemeld op\r\n` + fileData;
                const blob = new Blob([fileData], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.download = `Registraties_${format(startDate, 'yyyy-MM-dd')}_${format(endDate, 'yyyy-MM-dd')}.csv`;
                link.href = url;
                link.click();
            }
        }
        catch (err) {
            toast.error("Fout bij exporteren van de registraties.");
        }
    };

    return (
        <>
            <div className="row justify-content-md-center">
                <div className="col-md-auto">
                    <h6 className="d-inline-flex me-3 fw-bold">Van:</h6>
                    <DatePicker selected={startDate} onChange={(date: Date) => setStartDate(date)} className="form-control text-center" locale="nlBE" dateFormat="dd/MM/yyyy" />
                </div>
                <div className="col-md-auto">
                    <h6 className="d-inline-flex me-3 fw-bold">Tot:</h6>
                    <DatePicker selected={endDate} onChange={(date: Date) => setEndDate(date)} className="form-control text-center" locale="nlBE" dateFormat="dd/MM/yyyy" />
                </div>

                <div className="col-md-auto">
                    <button type="button" className="btn btn-primary text-light float-end" onClick={() => exportRegistraties()}>Exporteer</button>
                </div>
            </div>
            <div className="row">
                <div className="col table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Voornaam</th>
                                <th>Achternaam</th>
                                {configuration?.departmentsActive === true ? <th>Afdeling</th> : null}
                                {configuration?.cityFieldActive === true ? <th>Woonplaats</th> : null}
                                {configuration?.businessFieldActive === true ? <th>Bedrijf</th> : null}
                                {configuration?.cellphoneFieldActive === true ? <th>GSM</th> : null}
                                {configuration?.reasonsFieldActive === true ? <th>Bezoek</th> : null}
                                <th>Aangemeld</th>
                                <th>Afgemeld</th>
                            </tr>
                        </thead>
                        <tbody>
                            {guests?.map(guest => (
                                <tr key={guest.id}>
                                    <td>{guest.firstName}</td>
                                    <td>{guest.lastName}</td>
                                    {configuration?.departmentsActive === true ? <td>{guest.department}</td> : null}
                                    {configuration?.cityFieldActive === true ? <td>{guest.city}</td> : null}
                                    {configuration?.businessFieldActive === true ? <td>{guest.companyName}</td> : null}
                                    {configuration?.cellphoneFieldActive === true ? <td>{guest.phone}</td> : null}
                                    {configuration?.reasonsFieldActive === true ? <td>{guest.personVisited?.length < 1 ? '/' : guest.personVisited}</td> : null}
                                    <td>
                                        {new Date(guest.date).toLocaleString("nl-BE")}
                                    </td>
                                    <td>
                                        {guest.isPresent ? <button className="btn btn-primary text-light btn-sm" onClick={() => updateGuest(guest.id)}>Afmelden</button> : isSignOutDate(guest)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

function ErrorComponent(authRes: MsalAuthenticationResult) {
    return <p>An Error Occurred: {authRes.error?.message}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

export default function ConfigurationPage() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            <GuestsContent />
        </MsalAuthenticationTemplate>
    )
};