import { MsalAuthenticationTemplate, useMsal, MsalAuthenticationResult } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import { Controller, useForm } from 'react-hook-form';
import GuestPortalApiService from "../Services/GuestPortalApiService";
import GuestModel from "../Models/GuestModel";
import { toast } from 'react-toastify';
import { useHistory } from "react-router-dom";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { getError } from "../appInsights";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { useCallback, useEffect, useMemo, useState } from "react";
import VisitReasonModel from "../Models/VisitReasonModel";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';
import DepartmentModel from "../Models/DepartmentModel";
import ConfigurationModel from "../Models/ConfigurationModel";
import MuiPhoneNumber from '../Components/MuiPhoneInput';
import { MuiTextFieldInput } from "../Components/MuiTextFieldInput";

function GuestContent(props: any) {
    const appInsights = useAppInsightsContext();
    const { instance } = useMsal();
    const [selectedGuest, setSelectedGuest] = useState<GuestModel>({
        firstName: '',
        lastName: '',
        companyName: '',
        phone: '',
        personVisited: '',
        date: new Date(),
        isPresent: true,
        id: 0,
        signOutDate: new Date(),
        city: '',
        department: ''
    });

    const { register, setValue, handleSubmit, formState: { errors }, control } = useForm<GuestModel>({
        mode: "onChange", defaultValues: {
            firstName: '',
            lastName: '',
            companyName: '',
            phone: '',
            personVisited: '',
            city: '',
            department: ''
        }
    });
    const service = useMemo(() => new GuestPortalApiService(instance), [instance]);
    const history = useHistory();
    const [visitReasons, setVisitReasons] = useState<VisitReasonModel[]>([]);
    const [departments, setDepartments] = useState<DepartmentModel[]>([]);
    const [configuration, setConfiguration] = useState<ConfigurationModel>();
    const [guests, setGuests] = useState<GuestModel[]>([]);
    const [uniqueGuests, setUniqueGuest] = useState<GuestModel[]>([]);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<GuestModel[]>([]);
    const { t } = useTranslation(props.selectedLanguage);

    const onSubmit = handleSubmit((data) => {
        async function save(data: GuestModel) {
            try {
                await service.addGuest(data);

                toast.success(t('loginSuccessful'));
            }
            catch (error) {
                toast.error("Fout bij aanmelden.");
                appInsights.trackException({ exception: getError(error), severityLevel: SeverityLevel.Error });
            }
        }

        save(data);
        history.push('/choice');
    })

    const init = useCallback(async () => {
        try {

            const config = await service.getConfiguration();
            const reasons = await service.getVisitReasons();
            const departments = await service.getDepartments();
            const guests = await service.getAllGuests();

            setConfiguration(config);
            setVisitReasons(reasons);
            setDepartments(departments);
            setGuests(guests);
        }
        catch (error) {
            toast.error("Fout bij ophalen.");
            appInsights.trackException({ exception: getError(error), severityLevel: SeverityLevel.Error });
        }
    }, [service, appInsights]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted)
            init();

        return () => { isMounted = false }
    }, [init]);

    const countries = {
        "BE": "België",
        "DE": "Duistland",
        "FR": "Frankrijk",
        "LU": "Luxemburg",
        "NL": "Nederland",
        "ZZ": "Internationaal"
    };

    function isGuestModel(obj: any): obj is GuestModel {
        if (typeof obj !== 'object' || obj === null) {
            return false;
        }

        return (
            typeof obj.firstName === 'string' &&
            typeof obj.lastName === 'string' &&
            typeof obj.id === 'number'
        );
    }

    useEffect(() => {
        setValue("firstName", selectedGuest.firstName?.trim());
        setValue("lastName", selectedGuest.lastName?.trim());
        if (configuration?.departmentsActive) {
            setValue("department", selectedGuest?.department);
        }
        if (configuration?.cityFieldActive) {
            setValue("city", selectedGuest?.city?.trim());
        }
        if (configuration?.businessFieldActive) {
            setValue("companyName", selectedGuest?.companyName?.trim());
        }
        if (configuration?.cellphoneFieldActive) {
            setValue("phone", selectedGuest?.phone);
        }
        if (configuration?.reasonsFieldActive) {
            setValue("personVisited", selectedGuest?.personVisited);
        }

        setInputValue(selectedGuest.firstName?.trim());
    }, [selectedGuest, configuration, setValue]);

    useEffect(() => {
        const unique = guests.filter((obj, index) => {
            return index === guests.findIndex(o => obj.firstName.toLowerCase().trim() === o.firstName.toLowerCase().trim() && obj.lastName.toLowerCase().trim() === o.lastName.toLowerCase().trim());
        });
        setUniqueGuest(unique)
    }, [guests]);

    useEffect(() => {
        if (inputValue.length >= 3) {
            setOptions(uniqueGuests)
        } else {
            setOptions([])
        }
    }, [inputValue, uniqueGuests]);

    return (
        <form onSubmit={onSubmit} className="mx-3" autoComplete="off">
            <div className="row">
                <div className="mb-2 col-sm-6">
                    {configuration?.autocompleteActive ?
                        <Autocomplete
                            freeSolo
                            onChange={(event: any, newValue: string | null | GuestModel) => {
                                if (newValue) {
                                    if (isGuestModel(newValue)) {
                                        setSelectedGuest(newValue)
                                    }
                                }
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue, reason) => {
                                setInputValue(newInputValue)
                                if (reason === 'clear') {
                                    setValue("firstName", '')
                                    setValue("lastName", '')
                                    setValue("city", '');
                                    setValue("companyName", '');
                                    setValue("phone", '');
                                    setValue("department", '');
                                    setValue("personVisited", '');
                                }
                            }}
                            options={
                                options.sort(function (a: any, b: any) {
                                    if (a.key < b.key) {
                                        return -1;
                                    }
                                    if (a.key > b.key) {
                                        return 1;
                                    }
                                    return 0;
                                })
                            }
                            getOptionLabel={(option: string | GuestModel) => {
                                if (typeof option === 'string') {
                                    return option
                                } else {
                                    return option.firstName + ' ' + option.lastName
                                }
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={<>{t('firstname')} <span className="text-danger">*</span></>}
                                    placeholder={t('firstname')}
                                    variant="outlined"
                                    error={Boolean(errors?.firstName)}
                                    helperText={errors?.firstName?.message}
                                    fullWidth
                                    margin="dense"
                                    {...register("firstName", { required: true })}
                                />
                            )}

                        />
                        :
                        <MuiTextFieldInput
                            control={control}
                            label={<>{t('firstname')} <span className="text-danger">*</span></>}
                            placeholder={t('firstname')}
                            errorValue={Boolean(errors?.firstName)}
                            helperText={errors?.firstName?.message}
                            name="firstName"
                            rules={{ required: true }}
                        />
                    }
                    {errors.firstName && <div className="form-text text-danger">{t('firstnameRequiredNotification')}</div>}
                </div>
                <div className="mb-2 col-sm-6">
                    <MuiTextFieldInput
                        control={control}
                        label={<>{t('lastname')} <span className="text-danger">*</span></>}
                        placeholder={t('lastname')}
                        errorValue={Boolean(errors?.lastName)}
                        helperText={errors?.lastName?.message}
                        name="lastName"
                        rules={{ required: true }}
                    />
                    {errors.lastName && <div className="form-text text-danger">{t('lastnameRequiredNotification')}</div>}
                </div>
            </div>

            {configuration?.departmentsActive &&
                <div className="mb-2">
                    <Controller
                        name="department"
                        control={control}
                        rules={{ required: true }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                            formState,
                        }) => (
                            <TextField
                                select
                                label={<>{t('department')} <span className="text-danger">*</span></>}
                                placeholder={t('department')}
                                variant="outlined"
                                helperText={errors?.department?.message}
                                fullWidth
                                margin="dense"
                                SelectProps={{
                                    native: true,
                                }}
                                error={!!error}
                                onChange={onChange}
                                value={value}
                            >
                                <option key="0" value=""></option>
                                {departments?.map((option) => (
                                    <option key={option.id} value={option.name}>{option.name}</option>
                                ))}
                            </TextField>
                        )}
                    />
                    {errors.department && <div className="form-text text-danger">{t('department')} {t('requiredNotification')}</div>}
                </div>
            }

            {configuration?.cityFieldActive &&
                <div className="mb-2">
                    <MuiTextFieldInput
                        control={control}
                        label={<>{t('city')} <span className="text-danger">*</span></>}
                        placeholder={t('city')}
                        errorValue={Boolean(errors?.city)}
                        helperText={errors?.city?.message}
                        name="city"
                        rules={{ required: true }}
                    />
                    {errors.city && <div className="form-text text-danger">{t('city')} {t('requiredNotification')}</div>}
                </div>
            }

            {configuration?.businessFieldActive &&
                <div className="mb-2">
                    <MuiTextFieldInput
                        control={control}
                        label={<>{t('companyName')} <span className="text-danger">*</span></>}
                        placeholder={t('companyName')}
                        errorValue={Boolean(errors?.companyName)}
                        helperText={errors?.companyName?.message}
                        name="companyName"
                        rules={{ required: true }}
                    />
                    {errors.companyName && <div className="form-text text-danger">{t('companyName')} {t('requiredNotification')}</div>}
                </div>
            }

            {configuration?.cellphoneFieldActive &&
                <div className="mb-2">
                    <Controller
                        name="phone"
                        control={control}
                        rules={{
                            required: false,
                            validate: (value) => {
                                let valid = false;
                                if (value === undefined || value == null || value === '')
                                    valid = true;
                                else if (isValidPhoneNumber(value))
                                    valid = true;
                                else
                                    valid = false;
                                return valid;
                            }
                        }}
                        render={({ field: { onChange, value } }) => (
                            <PhoneInput
                                placeholder="GSM"
                                countrySelectProps={{ unicodeFlags: true }}
                                countries={["BE", "DE", "FR", "LU", "NL"]}
                                international={false}
                                defaultCountry="BE"
                                labels={countries}
                                value={value}
                                onChange={onChange}
                                error={Boolean(errors?.phone)}
                                inputComponent={MuiPhoneNumber}
                            />)}
                    />
                    {errors.phone && <div className="form-text text-danger">{t('phoneNotCorrect')}</div>}
                </div>
            }

            {configuration?.reasonsFieldActive &&
                <div className="mb-2">
                    <Controller
                        name="personVisited"
                        control={control}
                        rules={{ required: true }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                            formState,
                        }) => (
                            <TextField
                                select
                                label={<>{t('reasonOfVisit')} <span className="text-danger">*</span></>}
                                placeholder={t('reasonOfVisit')}
                                variant="outlined"
                                helperText={errors?.personVisited?.message}
                                fullWidth
                                margin="dense"
                                SelectProps={{
                                    native: true,
                                }}
                                error={!!error}
                                onChange={onChange}
                                value={value}
                            >
                                <option key="0" value=""></option>
                                {visitReasons?.map((option) => (
                                    <option key={option.id} value={option.reason}>{option.reason}</option>
                                ))}
                            </TextField>
                        )}
                    />
                </div>
            }

            <button type="submit" className="btn btn-primary w-100 mt-2 text-light btn-lg p-3 custom-primary-background custom-primary-border-color" ><b>{t('login')}</b></button>
            <div className="mt-2 fw-light fst-italic">
                <small>{t('loginInfo')}</small>
            </div>
        </form>
    );
};

function ErrorComponent(authRes: MsalAuthenticationResult) {
    return <p>An Error Occurred: {authRes.error?.message}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

export default function Guest() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            <GuestContent />
        </MsalAuthenticationTemplate>
    )
};
