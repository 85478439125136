import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import nl from "./languages/nl.json"
import en from "./languages/en.json"
import pt from "./languages/pt.json"
import fr from "./languages/fr.json"
import de from "./languages/de.json"
import ro from "./languages/ro.json"
import pl from "./languages/pl.json"
import tr from "./languages/tr.json"

i18n.use(initReactI18next).init({
  resources: {
    nl: { translation: nl },
    en: { translation: en },
    pt: { translation: pt },
    fr: { translation: fr },
    de: { translation: de },
    ro: { translation: ro },
    pl: { translation: pl },
    tr: { translation: tr },

  },
  lng: "nl",
  fallbackLng: "nl",
});

export default i18n;