import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import { ReactNode } from "react";

interface MuiTextFieldInputProps {
    name: string;
    label: ReactNode;
    placeholder?: string;
    control?: any;
    errorValue?: boolean;
    helperText?: string;
    rules?: any;
}

export const MuiTextFieldInput = ({ name, control, label, placeholder, errorValue, helperText, rules }: MuiTextFieldInputProps) => {

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({
                field: { onChange, value },
                fieldState: { error },
                formState,
            }) => (
                <TextField
                    label={label}
                    placeholder={placeholder}
                    variant="outlined"
                    error={!!error}
                    helperText={helperText}
                    fullWidth
                    margin="dense"
                    onChange={onChange}
                    value={value}
                />
            )}
        />
    );
};