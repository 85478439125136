import { useMsal } from '@azure/msal-react';
import React, { useEffect, useMemo, useState } from 'react';
import GuestPortalApiService from '../Services/GuestPortalApiService';
import { Chart as ChartJS, CategoryScale, Tooltip, LinearScale, BarElement, Title } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import moment from 'moment';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

function Dashboard() {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Tooltip,
        Title
    );

    const { instance } = useMsal();
    const service = useMemo(() => new GuestPortalApiService(instance), [instance]);
    const [chartData, setChartData] = useState<number[]>();
    const [chartLabels, setChartLabels] = useState<string[]>();
    const [daysView, setDaysView] = useState<number>(0);

    useEffect(() => {
        const fetchGuests = async () => {
            try {
                try {
                    let config = await service.getConfiguration();
                    setDaysView(config.overviewDays);
                }
                catch (error) {
                    setDaysView(7);
                }

                const guestOverview = await service.getGuestsOverview(daysView);
                const LabelsArr = guestOverview.map((g) => moment(g.day).format('DD/MM'));
                const CountArr = guestOverview.map((g) => g.guestCount);
                setChartLabels(LabelsArr);
                setChartData(CountArr);
            }
            catch (error) {
                toast.error("Fout bij aanmelden.");
            }
        }
        fetchGuests()
    }, [service, daysView]);

    const options = {
        responsive: true,
        scales: {
            y: {
                ticks: {
                    stepSize: 5
                }
            }
        },
        plugins: {
            title: {
                display: true,
                text: 'Aantal bezoekers per dag'
            }
        }
    };

    const state = {
        labels: chartLabels,
        datasets: [
            {
                barPercentage: 0.8,
                label: 'Gasten',
                backgroundColor: 'rgba(127, 184, 235, 0.9)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 0,
                data: chartData
            }
        ]
    }

    return (
        <div className="justify-content-center align-items-center">
            <div className="row m-3 w-100">
                <h3>Bezoekersregistratie</h3>
            </div>
            <div className="row m-3 w-100">
                <div className="col-sm-4">
                    <Link to="/choice" className="btn btn-primary text-light w-100 btn-lg p-4">App starten</Link>
                </div>
            </div>
            <div className="row m-3 w-100">
                <h3>Beheer</h3>
            </div>
            <div className="row m-3 w-100">
                <div className="col-sm-4">
                    <Link to="/guests" className="btn btn-primary text-light w-100 btn-lg p-4">Registraties</Link>
                </div>
                <div className="col-sm-4">
                    <Link to="/config" className="btn btn-primary text-light w-100 btn-lg p-4">Configuratie</Link>
                </div>
                <div className="col-sm-4">
                    <Link to="/visitreasons" className="btn btn-primary text-light w-100 btn-lg p-4">Reden bezoek</Link>
                </div>
            </div>
            <div className="row m-3 w-100">
                {chartData?.length !== 0 && <h3>Bezoekersoverzicht van de laatste {daysView} dagen</h3>}
            </div>
            <div className="row m-3 w-100">
                <div className="chart-container">
                    {chartData?.length !== 0 && <Bar options={options} data={state} />}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
