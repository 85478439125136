import { useEffect, useState, useMemo, useCallback } from "react";
import { MsalAuthenticationTemplate, useMsal, MsalAuthenticationResult } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";
import GuestPortalApiService from "../Services/GuestPortalApiService";
import GuestModel from "../Models/GuestModel";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useHistory } from "react-router-dom";
import { useAppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { getError } from "../appInsights";
import { useForm, SubmitHandler } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

function GuestsLogoutContent(props: any) {
    const appInsights = useAppInsightsContext();
    const { instance } = useMsal();
    const [presentGuests, setPresentGuests] = useState<GuestModel[]>([]);
    const { register, setValue, handleSubmit } = useForm<GuestModel>({ mode: "onChange" });
    const service = useMemo(() => new GuestPortalApiService(instance), [instance]);
    const history = useHistory();
    const { t } = useTranslation(props.selectedLanguage);
    const [selectedGuest, setSelectedGuest] = useState<GuestModel>();

    const init = useCallback(async () => {
        try {
            const today = new Date();
            let guests = await service.getGuests(today);
            guests.forEach((g) => {
                if (g.isPresent) {
                    setPresentGuests(presentGuests => [...presentGuests, g])
                }
            })
        }
        catch (error) {
            toast.error("Fout bij ophalen.");
            appInsights.trackException({ exception: getError(error), severityLevel: SeverityLevel.Error });
        }
    }, [service, appInsights]);

    useEffect(() => {
        let isMounted = true;
        if (isMounted)
            init();

        return () => { isMounted = false }
    }, [init]);

    async function updateGuest(id: number) {
        try {
            await service.updateGuest(id);
            toast.success(t("logoutSuccessful"));
            init();
        }
        catch (error) {
            toast.error(t("errorRetrieving"));
            appInsights.trackException({ exception: getError(error), severityLevel: SeverityLevel.Error });
        }
    }

    const onSubmit: SubmitHandler<GuestModel> = (data) => {
        if (selectedGuest === undefined) {
            return;
        }
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div id="overlay">
                        <div id="modal">
                            <h3 className="pb-3">{t('logout')}{" " + selectedGuest?.firstName + " " + selectedGuest?.lastName} ?</h3>
                            <button className="btn btn-primary text-light btn-lg p-3 px-5 mx-3 fs-1 custom-primary-background custom-primary-border-color"
                                onClick={() => {
                                    onClose();
                                    updateGuest(selectedGuest?.id);
                                    history.push('/choice')
                                }}
                            >
                                {t('logout')}
                            </button>
                            <button className="btn btn-primary text-light btn-lg p-3 px-5 mx-3 fs-1 custom-primary-background custom-primary-border-color" onClick={onClose}>{t('cancel')}</button>
                        </div>
                    </div>
                );
            }
        });
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="mx-3" autoComplete="off">
            <div className="row justify-content-md-center mb-2">
                <div className="col-md-9">
                    <Autocomplete
                        inputValue={selectedGuest?.firstName.concat(" ", selectedGuest?.lastName)}
                        disableClearable
                        onChange={(event, newInputValue) => {
                            if (newInputValue === null) {
                                setSelectedGuest(undefined)
                                setValue("id", 0);
                            }
                            else if (presentGuests.filter((g) => newInputValue.toLowerCase().includes(g.firstName.toLowerCase()) && newInputValue.toLowerCase().includes(g.lastName.toLowerCase()))[0]) {
                                const newSelectedGuest = presentGuests.filter((g) => newInputValue.toLowerCase().includes(g.firstName.toLowerCase()) && newInputValue.toLowerCase().includes(g.lastName.toLowerCase()))[0]
                                setSelectedGuest(newSelectedGuest)
                                setValue("id", newSelectedGuest?.id);
                            }
                        }}
                        onInputChange={(event, newInputValue, reason) => {
                            if (presentGuests.filter((g) => newInputValue.toLowerCase().includes(g.firstName.toLowerCase()) && newInputValue.toLowerCase().includes(g.lastName.toLowerCase()))[0]) {
                                const newSelectedGuest = presentGuests.filter((g) => newInputValue.toLowerCase().includes(g.firstName.toLowerCase()) && newInputValue.toLowerCase().includes(g.lastName.toLowerCase()))[0]
                                setSelectedGuest(newSelectedGuest)
                                setValue("id", newSelectedGuest?.id);
                            }
                        }}
                        freeSolo
                        options={
                            presentGuests.filter((obj, index) => presentGuests.findIndex((item) => item.firstName.toLowerCase() === obj.firstName.toLowerCase() && item.lastName.toLowerCase() === obj.lastName.toLowerCase()) === index)
                                .map((option) => option.firstName.toLowerCase().concat(" ", option.lastName.toLowerCase()))
                                .sort()
                        }
                        renderInput={(params) => (
                            <TextField
                                key={params.id}
                                {...params}
                                placeholder={t('findFirstname')}
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                                {...register("id", { required: true })}
                            />
                        )}
                    />

                    <button type="submit" className="btn btn-primary w-100 mt-2 text-light btn-lg p-3 custom-primary-background custom-primary-border-color" ><b>{t('logout')}</b></button>
                </div>
            </div>
        </form>
    );
};

function ErrorComponent(authRes: MsalAuthenticationResult) {
    return <p>An Error Occurred: {authRes.error?.message}</p>;
}

function LoadingComponent() {
    return <p>Authentication in progress...</p>;
}

export default function LogoutGuest() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={LoadingComponent}
        >
            <GuestsLogoutContent />
        </MsalAuthenticationTemplate>
    )
};