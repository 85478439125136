import { IPublicClientApplication } from "@azure/msal-browser";
import ConfigurationModel from "../Models/ConfigurationModel";
import GuestModel from "../Models/GuestModel";
import GuestOverviewModel from "../Models/GuestOverviewModel";
import VisitReasonModel from "../Models/VisitReasonModel";
import DepartmentModel from "../Models/DepartmentModel";

export default class GuestPortalApiService {

    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async getHeaders(): Promise<string[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/getheaders';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return result.json();
    }

    public async getClaims(): Promise<string[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/getclaims';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return result.json();
    }

    public async getConfiguration(): Promise<ConfigurationModel> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/GetConfiguration';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return result.json();
    }

    public async updateConfiguration(data: ConfigurationModel): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/updateconfiguration';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'post',
            body: JSON.stringify(data),
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return true;
    }

    public async addLogo(logo: any): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/addLogo';

        let token = await this.acquireAccessToken();
        let result = await fetch(url, {
            method: 'post',
            body: logo,
            headers: new Headers({
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return true;
    }

    public async deleteLogo(logo: string): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/deleteLogo';

        let token = await this.acquireAccessToken();
        let result = await fetch(url, {
            method: 'post',
            body: JSON.stringify(logo),
            headers: new Headers({
                "Content-Type": "multipart/form-data",
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return true;
    }

    public async addGuest(data: GuestModel): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/addguest';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'post',
            body: JSON.stringify(data),
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return true;
    }

    public async getGuests(date: Date): Promise<GuestModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/GetGuests?date=' + this.formatDate(date);

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return result.json();
    }

    public async getAllGuests(): Promise<GuestModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/GetAllGuests';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return result.json();
    }


    public async getGuestsFromPeriod(startDate: Date, endDate: Date): Promise<GuestModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/getGuestsFromPeriod?startDate=' + this.formatDate(startDate) + '&endDate=' + this.formatDate(endDate);

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return result.json();
    }

    public async getGuestsOverview(days: number): Promise<GuestOverviewModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/GetGuestOverview?days=' + days;

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return result.json();
    }

    public async updateGuest(id: number): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/updateguest';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'post',
            body: JSON.stringify(id),
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return true;
    }

    public async getVisitReasons(): Promise<VisitReasonModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/getvisitreasons';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return result.json();
    }

    public async deleteVisitReason(id: number): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/deletevisitreason';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'post',
            body: JSON.stringify(id),
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return true;
    }

    public async addVisitReason(data: VisitReasonModel): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/addvisitreason';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'post',
            body: JSON.stringify(data),
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return true;
    }

    public async getDepartments(): Promise<DepartmentModel[]> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/getdepartments';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return result.json();
    }

    public async deleteDepartment(id: number): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/deletedepartment';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'post',
            body: JSON.stringify(id),
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return true;
    }

    public async upsertDepartments(data: DepartmentModel[]): Promise<boolean> {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/upsertDepartments';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'post',
            body: JSON.stringify(data),
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }

        return true;
    }

    // public async sendMail(data: MailModel): Promise<boolean> {
    //     let baseUrl = process.env.REACT_APP_API_URL;
    //     let url = baseUrl + '/api/sendmail';

    //     let token = await this.acquireAccessToken();

    //     let result = await fetch(url, {
    //         method: 'post',
    //         body: JSON.stringify(data),
    //         headers: new Headers({
    //             "Authorization": "Bearer " + token
    //         })
    //     });

    //     if (!result.ok) {
    //         throw Error(result.statusText);
    //     }

    //     return true;
    // }

    public async alive() {
        let baseUrl = process.env.REACT_APP_API_URL;
        let url = baseUrl + '/api/Alive';

        let token = await this.acquireAccessToken();

        let result = await fetch(url, {
            method: 'get',
            headers: new Headers({
                "Authorization": "Bearer " + token
            })
        });

        if (!result.ok) {
            throw Error(result.statusText);
        }
    }

    acquireAccessToken = async () => {
        const activeAccount = this.msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API        
        const accounts = this.msalInstance.getAllAccounts();
        if (!activeAccount && accounts.length === 0) {
            /*
            * User is not signed in. Throw error or wait for user to login.
            * Do not attempt to log a user in outside of the context of MsalProvider
            */
        }
        const request = {
            scopes: ["https://bybguestportal.onmicrosoft.com/6e8b93f9-9f67-4a40-9a37-16b91e0ddbb3/user_access"],
            account: activeAccount || accounts[0]
        };

        const authResult = await this.msalInstance.acquireTokenSilent(request);

        return authResult.accessToken;
    };

    formatDate(date: Date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }
}